export default {
  ar: {
    home: "الصفحة الرئيسية",
    langBtn: "English",
    candidatePortal: "بوابة المتقدمين",
    notifications: "الإشعارات",
    otp: {
      title: "رمز التحقق",
      desc: "تم إرسال رمز مكون من 4 أرقام إلى بريدك الإلكتروني",
      resend: "اعادة ارسال",
      confirm: "تأكيد",
      expire: "انتهت صلاحية رمز التحقق",
    },
    Home: {
      Home: "الرئيسية",
      visitWebsite: "زيارة الموقع",
      Vacancies: "الوظائف الشاغرة",
      AboutUs: "عننا",
      ContactUs: "تواصل معنا",
      Companies: "الشركات",
      FindYour: "إبحث عن",
      DreamJobHere: "وظيفة احلامك هنا",
      RecommendedJobs: "موصى به",
      Posted: "تم النشر",
    },
    Footer: {
      Service: "Service",
      Address: "Adress",
      Contact_Us: "Contact Us",
    },
    errors: {
      fillAllForm: "*الرجاء ملئ جميع الحقول",
      FileMaxSize5MB: "يجب ان لا يتعدى حجم الملف 10 ميجا بايت",
      pdfOnly: "مسموح فقط ب PDF",
      required: "هذا الحقل مطلوب",
      nameError: "هذا الحقل يجب الا يتضمن اشكال خاصة ",
      validPhone: "رقم الهاتف غير صحيح",
      invalidEmail: "بريد الكترونى غير صحيح",
      invalidPassword:
        " رمز مرور خاطئ الاحرف على الاقل 8 و مزيج من الاحرف الكبيرة و الصغيرة و الارقام",
      confirmPasswordMatch: "كلمة المرور وتأكيد كلمة المرور لا تتطابق",
      imageRatio: "يجب ان تكون نسبة الصورة ١:١",
      invalidGPAValue: "يجب ان يكون الرقم مابين 0 - ",
      toGreaterThanFrom: "يجب ان تكون 'الى' اكبر 'من' ",
      chooseAnswers: "الرجاء اختيار اجاباتك",
      selctedLanguageBefore: "لقد قمت باختيار هذه اللغة من قبل",
      pdfWordFilesAllowed: "مسموح فقط بملفات PDF, Word",
      pdfImagesFilesAllowed: "مسموح فقط بملفات PDF, Word, JPG, PNG, JPEG",
      allowedSize: "يجب أن لا يتعدى حجم الملف 10 ميجا بايت",
      allowedVideoSize: "الحجم المسموح",
      videoAllowedTypes: "mp4 مسموح فقط بملفات",
    },
    shared: {
      done: "تم بنجاح",
      savedSuccessfuly: "لقد تم حفظ البيانات بنجاح",
      deactivatedCompany:
        "هذه البوابة غير متاحة الآن. الرجاء معاودة المحاولة في وقت لاحق!",
      interview: "المقابلة",
      logout: "الخروج",
      profile: "الملف الشخصى",
      interviewEnded: "تم انهاء الانترفيوا",
      file: "الملف",
    },
    auth: {
      signup: "سجل ",
      firstName: "الاسم الاول",
      lastName: "اسم العائلة",
      email: "البريد الالكترونى",
      forgetPassword: "نسيت كلمة المرور ؟",

      successRegister1: ` تم ارسال بريد الكتروني للتأكيد الى`,
      successRegister2: ` يرجى التحقق من بريدك الإلكتروني (يرجى التحقق ايضا" من مجلد الرسائل غير المرغوب فيها او البريد العشوائي ؛ إذا لم تتلق رسالة التأكيد الإلكترونية في غضون دقيقة واحدة ، فيرجى الضغط على "إرسال مرة أخرى")`,
      password: "كلمة المرور",
      confirmPassword: "تاكيد كلمة المرور",
      passwordValidRules: "يجب أن تتبع كلمة المرور القوية هذه القواعد:",
      passwordValidTipMin: "على الأقل 8 أحرف",
      passwordValidTipUpperLower: "مزيج من الأحرف الكبيرة والصغيرة",
      passwordValidTipLetterNumber: "مزيج من الحروف والأرقام",
      ForgetPasswordHeading: "نسيت كلمة المرور",
      Reset: "استعادة كلمة المرور",
      signin: "تسجيل الدخول",
      signupDesign: "سجل معنا للتقديم على الوظائف بسهولة",
      addPassword: "أضف كلمة المرور",
      signupMsg: "ليس لديك حساب؟ إنشاء حساب",
      signinMsg: "لديك حساب؟ سجل دخول",
      loginSharindDataAgreeMsg:
        "بالضغط علي سماح ، فإنك تسمح لنا بمشاركة ملفك مع عملائنا",
      allow: "سماح",
      disallow: "عدم سماح",
      sharing: "السماح بالنشر",
    },
    tabsHeader: {
      personal: "المعلومات الشخصية",
      advertQuestions: "الاستطلاع",
      skills: "المهارات",
      education: "التعليم",
      workExperience: "الخبرات",
      reference: "المرجع",
      licenses: "التراخيص والشهادات",
    },
    navBar: {
      myApplications: "وظائفى",
      home: "الرئيسية",
      myOffers: "عروضي الوظيفية",
    },
    buttons: {
      attachPDF: "ارفق PDF",
      readMore: "قراءة المزيد",
      CVfileType: "مسموح فقط PDF, Word",
      sendAgain: "ارسل مرة اخرى",
      send: "ارسال",
      addCV: "التسجيل",
      register: "تسجيل",
      delete: "حذف",
      login: "تسجيل الدخول",
      addSection: "أضف قسم",
      completeProfile: "استكمل ملفك الشخصي",
      save: "حفظ",
      cancel: "إلغاء",
      drag: "قص ونسخ",
      downloadAttachment: "تحميل الملف المرفق",
      uploadCv: "أرفع سيرتك الذاتيه",
      startInterview: "بدء المقابلة",
      uploadFile: "ارفع ملف",
      submit: "ارسال",
      reset: "مسح",
      uploadImage: "رفع صورة",
      dragCV: "أو أدرجها",
      download: "تحميل",
      accept: "قبول",
      decline: "رفض",
      sendforreview: " إرسال للتفاوض",
    },
    common: {
      NoDataFound: "لا يوجد بيانات",
      today: "اليوم",
      days: "ايام",
      day: "يوم",
      or: "او",
      add: "أضف",
    },
    vacancyInner: {
      jobDetailsHeader: "تفاصيل الوظيفة",
      jobLocation: "موقع الوظيفة",
      apply: "تقديم",
      profileErrMsg: "الرجاء إكمال الملف الشخصى للتمكن من التقديم على الوظائف",
      companyBrief: "كلمة عن الشركة",
      offers: "العروض",
      status: "Status",
      SentDate: "تاريخ الارسال",
      completeProfile: " إستكمال ملفك الشخصي",
    },
    educationExperience: {
      college: "الكلية",
      InstituteSchool: "الجهة التعليمية",
      degree: "الدرجة العلمية",
      FieldofStudy: "التخصص",
      gpa: "الدرجة /المعدل التراكمي",
      category: "تصنيف",
      jobTitle: "اسم الوظيفة",
      companyName: "اسم الشركة",
      location: "الموقع",
      from: "من",
      to: "إلى",
      description: "الوصف",
      addEducation: "إضافة المؤهل",
      editEducation: "تعديل المؤهل",
      toPresent: "إلى الوقت الحالى",
      addWorkExperience: "إضافة الخبرة",
      editWorkExperience: "تعديل الخبرة",
      present: "الوقت الحالى",
      outOf: "من أصل",
      customJobTitle: "ادخل اسم الوظيفة",
    },
    personalInfo: {
      personalInfoTitle: "معلومات شخصية",
      firstName: "الاسم الاول",
      lastName: "اسم العائلة",
      dateOfBirth: "تاريخ الميلاد",
      residency: "بلد الإقامة",
      city: "مدينة الإقامة",
      Residency: "الإقامة",
      gender: "الجنس",
      maritalStatus: "الحالة الاجتماعية",
      contactDetails: "بيانات الاتصال",
      phoneNumber: "رقم الجوال",
      personalEmail: "البريد الالكترونى",
      address: "العنوان",
      male: "ذكر",
      female: "انثى",
      nationality: "الجنسية",
    },
    skills: {
      skillsInfoTitle: "المهارات",
      profLevel: "مستوى الكفاءة",
      engProLevel: "ما هو مستوى إتقانك للغة الإنجليزية؟",
      languages: "اللغات",
      profLevelInEnglish: "مستوى إتقانك للغة الإنجليزية ",
      motherTongue: "اللغة الام",
    },
    licenses: {
      certificateName: "اسم الشهادة",
      issuingOrganization: "منظمة الإصدار",
      issuedDate: "تاريخ الاصدار",
      expirationDate: "تاريخ الصلاحية",
      noExpirationDate: "لا يوجد تاريخ صلاحية لهذه الشهادة",
    
      deleteLicenseContent:'هل ترغب في حذف هذه الشهادة ؟',
      deleteLicenseHead: "حذف الشهادة ",
    },
    filter: {
      postedDate: "تاريخ النشر",
      jobCategory: "نوع الوظيفة",
      contractType: "عقد العمل",
      filter: "تصفية",
      reset: "اعادة ضبط",
      jobTitle: "المسمى الوظيفى",
      country: "الدولة",
      city: "المدينة",
      search: "بحث",
      resetSearch: "اعادة",
    },
    screening: {
      screeningHeader: "الرجاء ملئ الاسئلة التالية:",
      date: "التاريخ",
      time: "الوقت",
      time_type: "النوع",
      text: "الاجابة",
    },
    advertQuestionsInfo: {
      advertQuestionsTitle: "الاستطلاع",
      currentLastSalary: "المرتب الحالى",
      currency: "العملة",
      currentLastPosition: "الوظيفة الحالية",
      currentLastCompany: "الشركة الحالية",
      unemployee: "باحث عن عمل",
      employed: "موظف",
      employeeStatus: "حالة الوظيفه",
    },
    snackbar: {
      codeResend: "تم اعادةإرسال الرمز إلى بريدك الإلكتروني",
      successApply: "شكرا لتقديمك على الوظيفة ، ستسمع منا قريبا",
      successFormSubmit: "شكرا تم تقديم النموذج بنجاح",
      done: "تم بنجاح",
      savedSuccessfuly: "تم حفظ البيانات بنجاح",
      interviewEnded: "تم انهاء المقابلة",
      successApprovedOffer: "شكرا لقبولك العرض, نتطلع إلى بدء العمل معك",
    },
    preoffer: {
      preofferTitle: "اضافة مرفقات",
      hello: "مرحبا",
      documentsNeededMsg: "الرجاء رفع الملفات التالية",
      preofferFinishedMsg: "شكرا لك على وقتك. ستسمع منا قريبا جدا",
      preofferFilledBeforeMsg: "شكرا لك, لقد قمت برفع الملفات من قبل",
    },
    moreInfoQuestions: {
      moreInfoRequest: "طلب معلومات إضافية",
      answersNeededMsg: "الأسئلة التالية يجب الإجابة عليها",
      uploadVideo: "ارفع الفيديو",
      answerTip: "الإجابة مفترض ان تكون في عدد",
      minutes: "دقائق",
      letters: "حروف",
      expirationDate: "ينتهي في",
      answer: "الاجابة",
      submittedBefore: "شكرا لك, لقد قمت برفع الملفات من قبل",
      expiredForm: "شكرا لك, لقد قمت برفع الملفات من قبل",
      requestChangeingDate: " طلب تغيير التاريخ",
      requestChangeDateContent:
        " تاريخ انتهاء الطلب هو اخر تاريخ انتهاء، ولكن يمكنكم طلب تغيير هذا إن أمكن  من فضلك اختر التاريخ، وانتظر الموافقة",
      newExpirationDate: "تاريخ الانتهاء الجديد",
      reason: "السبب",
      previewVideo: "تشغيل الفيديو",
    },
    emailRequests: {
      offerTitle: "العرض الوظيفي",
      contractTitle: "العقد",
      Signed: "التوقيع:",
      digitalDocument: "المستند الرقمي",
      JoiningDate: "التاريخ المتوقع للانضمام :",
      reason: "السبب",
      offerSubmited: "لقد قمت بتقديم هذا النموذج من قبل, شكرا لك",
    },
  },
};
