import * as types from "../types/DigitalDocuments";

export const getDigitalDocumentRequest = (payload) => ({
  type: types.GET_DIGITAL_DOCUMENT_REQUEST,
  payload,
});
export const getDigitalDocumentReceive = (payload) => ({
  type: types.GET_DIGITAL_DOCUMENT_RECEIVE,
  payload,
});


export const sendDigitalDocumentRequest = (payload) => ({
  type: types.SEND_DIGITAL_DOCUMENT_REQUEST,
  payload,
});
export const sendDigitalDocumentReceive = (payload) => ({
  type: types.SEND_DIGITAL_DOCUMENT_RECEIVE,
  payload,
});