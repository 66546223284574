import { all } from "redux-saga/effects";
import { LookupsSagasWatch } from "./Lookups";
import { AuthSagasWatch } from "./Auth";
import { HomeSagasWatch } from "./Home";
import { MyApplicationsSagasWatch } from "./MyApplications";
import { ProfileSagasWatch } from "./Profile";
import { ScreeningQuestionsSagasWatch } from "./ScreeningQuestions";
import { VideoInterviewSagasWatch } from "./videoInterview";
import { VacanciesSagasWatch } from "./Vacancies";
import { PreofferSagasWatch } from "./Preoffer";
import { MoreInformationQuestionsSagasWatch } from "./MoreInformationQuestions";
import { OfferSagasWatch } from "./Offer";
import { ContractSagasWatch } from "./Contract";
import { UploadCVModeSagasWatch } from "./UploadCVMode";
import {NotificationSagasWatch} from "../Notification/saga"
import { DigitalDocumentSagasWatch } from "./DigitalDocument";
export function* watchAll() {
  yield all([
    LookupsSagasWatch(),
    AuthSagasWatch(),
    HomeSagasWatch(),
    MyApplicationsSagasWatch(),
    ProfileSagasWatch(),
    ScreeningQuestionsSagasWatch(),
    VacanciesSagasWatch(),
    VideoInterviewSagasWatch(),
    PreofferSagasWatch(),
    MoreInformationQuestionsSagasWatch(),
    OfferSagasWatch(),
    UploadCVModeSagasWatch(),
    NotificationSagasWatch(),
    ContractSagasWatch(),
    DigitalDocumentSagasWatch()
  ]);
}
