import * as types from "../types/Offer";

export const getOfferDataRequest = (payload) => ({
  type: types.GET_OFFER_TEMPLATE_REQUEST,
  payload,
});
export const getOfferDataReceive = (payload) => ({
  type: types.GET_OFFER_TEMPLATE_RECEIVE,
  payload,
});

export const getAllOffersRequest = (payload) => ({
  type: types.GET_ALL_OFFER_REQUEST,
  payload,
});
export const getAllOffersRecive = (payload) => ({
  type: types.GET_ALL_OFFER_RECEIVE,
  payload,
});

export const uploadCandidateSignatureRequest = (payload) => ({
  type: types.UPLOAD_CANDIDATE_SIGNATURE_REQUEST,
  payload,
});
export const uploadCandidateSignatureReceive = (payload) => ({
  type: types.UPLOAD_CANDIDATE_SIGNATURE_RECEIVE,
  payload,
});
export const acceptOfferRequest = (payload) => ({
  type: types.APPROVE_OFFER_REQUEST,
  payload,
});
export const rejectOfferRequest = (payload) => ({
  type: types.REJECT_OFFER_REQUEST,
  payload,
});
export const reviewOfferRequest = (payload) => ({
  type: types.REVIEW_OFFER_REQUEST,
  payload,
});
export const downloadPDFFile = (payload) => ({
  type: types.DOWNLOAD_OFFER_FILE,
  payload,
})
