export const GET_OFFER_TEMPLATE_REQUEST = 'GET_OFFER_TEMPLATE_REQUEST';
export const GET_OFFER_TEMPLATE_RECEIVE = 'GET_OFFER_TEMPLATE_RECEIVE';

export const UPLOAD_CANDIDATE_SIGNATURE_REQUEST = 'UPLOAD_CANDIDATE_SIGNATURE_REQUEST';
export const UPLOAD_CANDIDATE_SIGNATURE_RECEIVE = 'UPLOAD_CANDIDATE_SIGNATURE_RECEIVE';

export const APPROVE_OFFER_REQUEST = 'APPROVE_OFFER_REQUEST';
export const APPROVE_OFFER_RECEIVE = 'APPROVE_OFFER_RECEIVE';

export const REJECT_OFFER_REQUEST = 'REJECT_OFFER_REQUEST';
export const REJECT_OFFER_RECEIVE = 'REJECT_OFFER_RECEIVE';

export const REVIEW_OFFER_REQUEST = 'REVIEW_OFFER_REQUEST';
export const REVIEW_OFFER_RECEIVE = 'REVIEW_OFFER_RECEIVE';

export const DOWNLOAD_OFFER_FILE = 'DOWNLOAD_OFFER_FILE';
export const UPLOAD_OFFER_FILE = 'UPLOAD_OFFER_FILE';

export const GET_ALL_OFFER_REQUEST = 'GET_ALL_OFFER_REQUEST';
export const GET_ALL_OFFER_RECEIVE = 'GET_ALL_OFFER_RECEIVE';
