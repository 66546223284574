import { call, put, takeLatest } from "redux-saga/effects";
import API from "../../network/apis/Auth";
import * as ACTION from "../actions/Auth";
import { dispatchError } from "../../utils/Shared";
import History from "../../routes/History";
import Auth from "../../utils/Auth";
import * as TYPE from "../types/Auth";
import Cookies from "js-cookie";
import { authRedirection, getEnv, getDomain } from "../../utils/redirection";


export function* signupRequest({ payload }) {
  try {
    const response = yield call(API.signupRequest, payload);
    History.push("/success");
    localStorage.setItem("registerationUserId", response.data.user_id);
  } catch (err) {
    dispatchError(err.response.data);
  }
}
export function* sendEmailAgainRequest({ payload }) {
  try {
    const response = yield call(API.sendEmailAgainRequest, payload);
    localStorage.setItem("registerationUserId", response.data.user_id);
  } catch (err) {
    dispatchError(err.response.data);
  }
}
export function* confirmEmailRequest({ payload }) {
  try {
    yield call(API.confirmEmailRequest, payload);
    window.location.href = authRedirection('login')
  } catch (err) {
    dispatchError(err.response.data);
  }
}
export function* forgetPasswordRequest({ payload }) {
  try {
    const forgetPasRes = yield call(API.forgetPasswordRequest, payload);
    yield put(ACTION.forgetPasswordReceive(forgetPasRes.data));
    window.location.href = authRedirection('login')
  } catch (err) {
    dispatchError(err.response.data);
  }
}
export function* signinRequest({ payload }) {
  try {
    const response = yield call(API.signinRequest, payload);
    yield put(ACTION.signinReceive(response.data.data));
    Cookies.set("token", response.data.data.token, { expires: 1, domain: getEnv() });
    Cookies.set("userData", response.data.data, { expires: 1, domain: getEnv() });
    localStorage.getItem("prevPath")
      ? History.push(localStorage.getItem("prevPath"))
      : History.push("/");
    localStorage.removeItem("prevPath");
    Auth.getAuth();
  } catch (err) {
    dispatchError(err.response.data);
  }
}

export function* resetPasswordRequest({ payload }) {
  try {
    const resettPassRes = yield call(API.resetPasswordRequest, payload);
    yield put(ACTION.resetPasswordReceive(resettPassRes.data));
    window.location.href = authRedirection('login')
  } catch (err) {
    dispatchError(err.response.data);
  }
}
export function* validateDomain(action) {
  try {
    const response = yield call(API.validateDomain, action.payload);
    yield put(ACTION.validateDomainReceive(response.data.data));
    getDomain();
    History.location.pathname === "/deactivated" && History.push("/");
  } catch (err) {
    if (err.response.status === 404) {
      History.push("/notFound");
    }
    // dispatchError(err.response.data);
  }
}

export function* AuthSagasWatch() {
  yield takeLatest(TYPE.SIGN_UP_REQUEST, signupRequest);
  yield takeLatest(TYPE.FORGET_PASSWORD_REQUEST, forgetPasswordRequest);
  yield takeLatest(TYPE.CONFIRM_EMAIL_REQUEST, confirmEmailRequest);
  yield takeLatest(TYPE.RESET_PASSWORD_REQUEST, resetPasswordRequest);
  yield takeLatest(TYPE.SEND_EMAIL_AGAIN_REQUEST, sendEmailAgainRequest);
  yield takeLatest(TYPE.SIGN_IN_REQUEST, signinRequest);
  yield takeLatest(TYPE.VALIDATE_DOMAIN_REQUEST, validateDomain);
}
