import { axiosInstance } from "./index";

let handlerEnabled = true;

const getDigitalDocument = async (document_id) => {
  return await axiosInstance.get(
    `/admin/Digital_Document/CandidateAppDigitalDocument/${document_id}`,
    {
      handlerEnabled,
    }
  );
};
const sendDigitalDocument = async ({ document_id, data }) => {
  return await axiosInstance.post(
    `/admin/Digital_Document/AddSignatureWithMail/${document_id}`,
    data,
    {
      handlerEnabled,
    }
  );
};
export default {
  getDigitalDocument,
  sendDigitalDocument,
};
