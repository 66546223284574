import * as types from "../types/DigitalDocuments";
import { GET_IMAGE_64_RECIEVE } from "../types/Contract";

const INITIAL_STATE = {
  digitalDocument: {},
  _CoverLetter: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_DIGITAL_DOCUMENT_RECEIVE:
      return { ...state, digitalDocument: action.payload };
    case GET_IMAGE_64_RECIEVE:
      return { ...state, _CoverLetter: action.payload };
    case types.SEND_DIGITAL_DOCUMENT_RECEIVE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
