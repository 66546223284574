import { call, put, takeLatest } from "redux-saga/effects";
import API from "../../network/apis/DigitalDocument";
import * as ACTIONS from "../actions/DigitalDocument";
import * as TYPE from "../types/DigitalDocuments";
import {
  dispatchError,
  showSnackbar,
  updateTokenFromUrl,
} from "../../utils/Shared";
import History from "../../routes/History";
import APIPROFILE from "../../network/apis/Profile";

export function* getDigitalDocument({ payload }) {
  try {
    const result = yield call(APIPROFILE.getCandidateCompanyRequest);
    result.data?.data.profile_type === 3 && updateTokenFromUrl();
    const response = yield call(API.getDigitalDocument, payload);
    yield put(ACTIONS.getDigitalDocumentReceive(response.data.data));
  } catch (err) {
    yield put(ACTIONS.getDigitalDocumentReceive(err?.response?.data));
  }
}
export function* sendDigitalDocument({ payload }) {
  try {
    const result = yield call(APIPROFILE.getCandidateCompanyRequest);
    result.data?.data.profile_type === 3 && updateTokenFromUrl();
    yield call(API.sendDigitalDocument, payload);
    showSnackbar("success");
    History.push("/");
  } catch (err) {
    dispatchError(err.response.data);
  }
}

export function* DigitalDocumentSagasWatch() {
  yield takeLatest(TYPE.GET_DIGITAL_DOCUMENT_REQUEST, getDigitalDocument);
  yield takeLatest(TYPE.SEND_DIGITAL_DOCUMENT_REQUEST, sendDigitalDocument);

}
