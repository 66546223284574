import Cookies from "js-cookie";
import store from "../store";


export const jobSeekerUrl = process.env.REACT_APP_JOB_SEEKER_URL;
export const authRedirection = (auth, job_post_id = null) => {
  const locale = store.getState().locale;
  if (auth === "login") {
    Cookies.set("redirect", window.location.href, {
      expires: 1,
      domain: getEnv(),
    });
    return `${jobSeekerUrl}/auth/login?job_post_id=${job_post_id}${`&domain=${getDomain()}&locale=${locale?.lang}`}`;
  } else if (auth === "signup") {
    return `${jobSeekerUrl}/auth/signup?job_post_id=${job_post_id}&domain=${getDomain()}&locale=${locale?.lang
      }`;
  } else if (auth === "forget-password") {
    return `${jobSeekerUrl}/auth/forgetPassword?job_post_id=${job_post_id}&domain=${getDomain()}&locale=${locale?.lang
      }`;
  }
};
export const getEnv = () => {
  return `.${process.env.REACT_APP_ENV}`;
};
export function getDomain() {
  let host = window.location.host;
  let parts = host.split(".");
  let domain = parts[0].split(process.env.REACT_APP_CANDIDATE_PORTAL_BASE_URL)[0];
  const domain_name = process.env.REACT_APP_DOMAIN
    ? process.env.REACT_APP_DOMAIN
    : domain;

  if (domain_name === "careers") {
    localStorage.setItem("domain", "adaa");
  } else {
    localStorage.setItem("domain", domain_name);
  }
  if (domain_name === "careers") return 'adaa'
  else return domain_name;
}
