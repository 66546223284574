import "./App.scss";
import React, { Component } from "react";
import { Router } from "react-router-dom";
import history from "../routes/History";
import Routes from "../routes/Routes";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import messages from "../assets/Local/messages";
import Loader from "../components/Loader/Loader";
import { SnackbarComponent } from "../components/Snackbar/Snackbar";
import { Helmet } from "react-helmet";
import { candidatePortalLogo } from "../utils/CandidatePortalColor";
import ReactGA from "react-ga";
import History from "../routes/History";
import { candidateCompanyRequest } from "../store/actions/Profile";
import { popupNotificationRequest } from "../store/Notification/actions";
import { getDomain } from "../utils/redirection";
import Auth from "../utils/Auth";
import "bootstrap/dist/js/bootstrap.bundle";
let hexToRgb = require("hex-rgb");
class App extends Component {
  constructor() {
    super();
    this.state = {
      pathname: "/",
    };
  }
  changeThemeColor = (primary_color, secondary_color) => {
    primary_color &&
      document.documentElement.style.setProperty(
        "--primary-color",
        primary_color
      );
    if (secondary_color) {
      document.documentElement.style.setProperty(
        "--secondary-color",
        secondary_color
      );
      const transparent_color = hexToRgb(secondary_color, {
        format: "array",
      });
      document.documentElement.style.setProperty(
        "--secondary-color-transparent",
        `rgba(${transparent_color[0]},${transparent_color[1]}, ${transparent_color[2]}, .3)`
      );
    }
  };
  componentDidMount() {
    localStorage.setItem('guide', true)
    if (
      process.env.NODE_ENV === "production" &&
      window.location.host === "careers.adaa.gov.sa"
    ) {
      // console.log("auth", Auth.getAuth());
      window.location.href = "https://careers.adaa.gov.sa";
    }
    this.props.candidateCompanyRequest();
    if (
      window.location.href.includes("candidate-stage.hrcom.ibtikar.sa") ||
      window.location.href.includes("stage.hrcom.site")
    ) {
      let meta = document.createElement("meta");
      meta.name = "robots";
      meta.content = "noindex, nofollow";
      document.getElementsByTagName("head")[0].appendChild(meta);
    }
    if (
      !window.location.href.includes("candidate-stage.hrcom.ibtikar.sa") ||
      !window.location.href.includes("stage.hrcom.site")
    ) {
      let meta = document.createElement("meta");
      meta.name = "google-site-verification";
      meta.content = "EBRnf3J53nKwEttHhQUOAC0NkrIgfKtzznrALUwdoBM";
      document.getElementsByTagName("head")[0].appendChild(meta);

      /* TRACKING WEBSITE */
      const trackingId = process.env.REACT_APP_CANDIDATE_GOOGLE_ANALYTICS_KEY;
      ReactGA.initialize(trackingId);
      const pathRoute = History.location.pathname.split("/")[1];
      const pageName = pathRoute === "" ? "home page" : pathRoute;
      ReactGA.pageview(
        `${localStorage.getItem("domain") + "/" + pathRoute}`,
        [],
        pageName
      );
    }

    localStorage.getItem("lang")
      ? localStorage.setItem("lang", localStorage.getItem("lang"))
      : localStorage.setItem("lang", this.props.lang);
    /**
     * INSTALL HOTJAR SCRIPT
     */
    // import('../utils/hotjar').then(() => {
    // })
    if (this.props.is_authenticated) {
      this.props.popupNotificationRequest();
      const getUnSeenNotifications = () => {
        this.props.popupNotificationRequest();
      };
      const interval = setInterval(getUnSeenNotifications, 60 * 1000);
    }

    window.Intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: "yzspq3ie",
    });
    window.Intercom("update");
  }

  componentDidUpdate() {
    if (History.location.pathname !== this.state.pathname) {
      this.setState({ pathname: History.location.pathname });
      const pathRoute = History.location.pathname.split("/")[1];
      const pageName = pathRoute === "" ? "home page" : pathRoute;
      ReactGA.set({ page: localStorage.getItem("domain") + "/" + pathRoute });
      ReactGA.pageview(
        `${localStorage.getItem("domain") + "/" + pathRoute}`,
        [],
        pageName
      );
    }
    if (this.props.candidateCompany.done) {
      const { primary_color, secondary_color } = this.props.candidateCompany;
      this.changeThemeColor(primary_color, secondary_color);
      const data = candidatePortalLogo(this.props.candidateCompany);
      localStorage.setItem("portalLogo", JSON.stringify(data));
      localStorage.setItem(
        "portalColor",
        JSON.stringify({ primary_color, secondary_color })
      );
    }
    if (this.props.is_authenticated) {
      if (history.location.pathname !== this.state.pathname) {
        this.props.popupNotificationRequest();
      }
      // let candidateId;
      // for (let i = 0; i < this.props.notifications?.length; i++) {
      //   if (this.props.notifications[i]?.meta_data) {
      //     candidateId = this.props.notifications[i]?.meta_data?.candidate_id;
      //     localStorage.setItem("CandidateID", candidateId);
      //   }
      // }
    }
  }

  render() {
    const { lang, loading } = this.props;
    return (
      <IntlProvider locale={lang} messages={messages[lang]}>
        <div
          className={lang === "ar" ? "rtl" : "ltr"}
          dir={lang === "ar" ? "rtl" : "ltr"}
        >
          <Helmet>
            <title>{getDomain()}</title>
          </Helmet>
          {loading ? <Loader /> : null}
          <Router history={history}>
            <SnackbarComponent />
            {Routes}
          </Router>
        </div>
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.locale.lang,
    loader: state.loader,
    candidateCompany: state.profile.candidateCompany,
    is_authenticated: state.auth.is_authenticated,
    notifications: state.notification.popupNotification.notifications,
  };
};

export default connect(mapStateToProps, {
  popupNotificationRequest,
  candidateCompanyRequest,
})(App);
