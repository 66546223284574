import React from "react";

export const Home = React.lazy(() => import("../containers/Home/Home"));
export const SignupSuccess = React.lazy(() =>
  import("../containers/Auth/Success/Success")
);
export const ResetPassword = React.lazy(() =>
  import("../containers/Auth/ResetPassword/ResetPassword")
);
export const ForgetPassword = React.lazy(() =>
  import("../containers/Auth/ForgetPassword/ForgetPassword")
);
export const NotFound = React.lazy(() =>
  import("../components/NotFound/NotFound")
);
export const VacancyInner = React.lazy(() =>
  import("../containers/VacancyInner/VacancyInner")
);
export const ScreeningQuestions = React.lazy(() =>
  import("../containers/ScreeningQuestions/ScreeningQuestions")
);
export const Profile = React.lazy(() =>
  import("../containers/Profile/Profile")
);
export const MyApplications = React.lazy(() =>
  import("../containers/MyApplications/MyApplications")
);
export const MyOffers = React.lazy(() =>
  import("../containers/MyOffers/MyOffers")
);
export const DeactivatedPortal = React.lazy(() =>
  import("../components/DeactivatedPortal/DeactivatedPortal")
);
export const VideoInterview = React.lazy(() =>
  import("../containers/VideoInterviewPage/VideoInterviewPage")
);
export const Preoffer = React.lazy(() =>
  import("../containers/Preoffer/Preoffer")
);
export const MoreInformationQuestions = React.lazy(() =>
  import("../containers/MoreInformationQuestions/MoreInformationQuestions")
);

export const Offer = React.lazy(() => import("../containers/Offer/Offer"));
export const DigitalDocument = React.lazy(() => import("../containers/DigitalDocument/DigitalDocument"));

export const Contractor = React.lazy(() => import("../containers/Contract/Contract"));
export const NotificationList  = React.lazy(() =>
import("../containers/NotificationList/NotificationList")
);
