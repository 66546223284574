import React, { Suspense } from "react";
import { Router, Switch } from "react-router-dom";
import { PrivateRoute } from "../utils/PrivateRoute";
import history from "./History";
import * as LazyComponent from "../utils/LazyLoaded";
import Loader from "../components/Loader/Loader";

const Routes = (
  <Suspense fallback={<Loader />}>
    <Router history={history}>
      <Switch>
        <LazyComponent.Home path="/" exact />
        <LazyComponent.SignupSuccess path="/success" exact />
        <LazyComponent.ResetPassword path="/resetPassword" exact />
        <LazyComponent.ForgetPassword path="/forgetpassword" exact />

        <LazyComponent.ResetPassword path="/ConfirmEmail" exact />
        <LazyComponent.VacancyInner path="/vacancyInner/:id" exact />
        <PrivateRoute
          path="/profile/:id?"
          isPublic={true}
          exact
          component={LazyComponent.Profile}
        />
        <LazyComponent.ScreeningQuestions
          path="/screeningQuestions/:id?"
          exact
        />

        <LazyComponent.MyApplications path="/MyApplications" exact />
        <LazyComponent.MyOffers path="/MyOffers" exact />

        <LazyComponent.DeactivatedPortal path="/deactivated" exact />
        <LazyComponent.VideoInterview path="/interview/:id" exact />
        <PrivateRoute
          path="/preoffer"
          isPublic={true}
          exact
          component={LazyComponent.Preoffer}
        />
        <PrivateRoute
          path="/moreInformationQuestions"
          isPublic={true}
          exact
          component={LazyComponent.MoreInformationQuestions}
        />
        <PrivateRoute
          path="/contractor"
          isPublic={true}
          exact
          component={LazyComponent.Contractor}
        />
        <PrivateRoute
          path="/offer"
          isPublic={true}
          exact
          component={LazyComponent.Offer}
        />
        <PrivateRoute
          path="/digitalDocument"
          isPublic={true}
          exact
          component={LazyComponent.DigitalDocument}
        />
        <PrivateRoute
          path="/notificationList"
          isPublic={true}
          exact
          component={LazyComponent.NotificationList}
        />
        <LazyComponent.NotFound
          path="**"
          title="This page doesn't exist..."
          exact
        />
      </Switch>
    </Router>
  </Suspense>
);

export default Routes;
